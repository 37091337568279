import { forwardRef, useState, useRef, useEffect, PropsWithChildren } from 'react';
import { Box, Button, Tooltip, IconButton, FormControlLabel, Switch } from '@mui/material';
import {
  MaterialReactTable,
  type MRT_Icons,
  MRT_ToggleGlobalFilterButton,
  MRT_ToggleFiltersButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_FullScreenToggleButton,
  MRT_ColumnOrderState,
  MRT_VisibilityState,
  MRT_DensityState,
  MRT_SortingState,
  MRT_ColumnFiltersState,
} from 'material-react-table';
import { useDispatch, useSelector } from 'react-redux';
import { utils, writeFile } from 'xlsx-js-style';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useSearchParams } from 'react-router-dom';

import { fetchUserSettings, updateUserSettings, patchUserSettings } from 'reduxApp/slices/user/userSettingsSlice';
import { useTranslation } from 'react-i18next';
import { getMRTLocale } from '../../../utils/i18n/helper';
import { getLocale } from '../../../utils/i18n/i18n';

export const CustomMRTable = forwardRef(
  (
    { columns, data, tableName, enableRowActions, styleFirstRow = false, children, ...rest }: PropsWithChildren<any>,
    forwardedRef: any | null
  ) => {
    const fontAwesomeIcons: Partial<MRT_Icons> = {
      DragHandleIcon: () => <DragIndicatorIcon />,
    };

    const { t } = useTranslation();

    const columnsFiltersType = () => {
      const filtersType: any = {};
      for (let i = 0; i < columns.length; i++) {
        const currentKey = columns[i]?.accessorKey || columns[i]?.id;
        let currentValue = 'value';
        if (columns[i]?.filterVariant === 'multi-select') {
          currentValue = 'array';
        }
        filtersType[currentKey] = currentValue;
      }
      return filtersType;
    };

    function isJSON(str: any) {
      if (Number(str) === str * 1) return str;
      try {
        const date = JSON.parse(str);
        return {
          from: new Date(date?.from),
          to: new Date(date?.to),
        };
      } catch (e) {
        return str;
      }
    }

    const createFiltersFromQueryParams = (qParams: any) => {
      const columnsFilters = columnsFiltersType();
      const objFilters = [];
      for (let i = 0; i < qParams.length; i++) {
        if (columnsFilters?.[qParams[i][0]] === 'value') {
          objFilters.push({
            id: qParams[i][0],
            value: isJSON(qParams[i][1]),
          });
        }
        if (columnsFilters?.[qParams[i][0]] === 'array') {
          let hasKey = false;
          for (let j = 0; j < objFilters.length; j++) {
            if (objFilters[j]?.id === qParams[i][0]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              objFilters[j].value = [...objFilters[j].value, qParams[i][1]];
              hasKey = true;
              break;
            }
          }
          if (!hasKey) {
            objFilters.push({
              id: qParams[i][0],
              value: [qParams[i][1]],
            });
          }
        }
      }
      return objFilters;
    };

    const [searchParams] = useSearchParams();
    const qParams: any = [...searchParams];

    const [tableInstanceRef] = useState(() => forwardedRef || rest.tableInstanceRef);

    const isFilterQueryParams = !!qParams && qParams.length > 0;

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (userId) dispatch(fetchUserSettings({ userId, userLevel }));
    }, []);

    const dispatch = useDispatch();
    const { id: userId, level: userLevel } = useSelector((state: any) => state.user.user);
    const userSettings = useSelector((state: any) => state.userSettings.userSettings);

    // START TABLE PERSISTANCE //
    const [isUserSettings, setIsUserSettings] = useState(false);
    const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([
      ...(enableRowActions ? ['mrt-row-actions'] : []),
      ...columns.map((c: any) => c.accessorKey || c.id),
    ]);
    const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
      rest?.initialState?.columnVisibility ? { ...rest.initialState.columnVisibility } : {}
    );
    const [density, setDensity] = useState<MRT_DensityState>('comfortable');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

    // filters
    const [isAutosaveFilters, setIsAutosaveFilters] = useState(false);
    const [showColumnFilters, setShowColumnFilters] = useState(isFilterQueryParams);
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([
      ...(isFilterQueryParams ? createFiltersFromQueryParams(qParams) : []),
    ]);

    const saveUserSettings = (type: string, obj: any) => {
      const bodyObj = {
        [tableName]: { [type]: obj },
      };

      if (type === 'isAutosaveFilters' && isAutosaveFilters) {
        const bodyObjFilters = {
          [tableName]: { isAutosaveFilters: true, showColumnFilters, columnFilters },
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(patchUserSettings({ userId, bodyObj: bodyObjFilters }));
        return true;
      }
      if (type === 'isAutosaveFilters' && !isAutosaveFilters) {
        const bodyObjFilters = {
          [tableName]: { isAutosaveFilters: false, showColumnFilters: false, columnFilters: [] },
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(patchUserSettings({ userId, bodyObj: bodyObjFilters }));
        return true;
      }

      if (!isAutosaveFilters && (type === 'showColumnFilters' || type === 'columnFilters')) return true;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(patchUserSettings({ userId, bodyObj }));
      return true;
    };

    const resetAllFilters = () => {
      const bodyObj = {
        [tableName]: { columnFilters: [] },
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(patchUserSettings({ userId, bodyObj }));
      setColumnFilters([]);
    };

    const setColumnFiltersFromJSON = (filtersArray: any) => {
      const objFiltersArray = JSON.parse(JSON.stringify(filtersArray));
      for (let i = 0; i < objFiltersArray.length; i++) {
        if (objFiltersArray[i]?.value?.from) objFiltersArray[i].value.from = new Date(filtersArray[i].value.from);
        if (objFiltersArray[i]?.value?.to) objFiltersArray[i].value.to = new Date(filtersArray[i].value.to);
      }
      setColumnFilters(objFiltersArray);
    };

    const updateTableFromUserSettings = () => {
      if (userSettings?.[tableName]?.columnOrder) {
        setColumnOrder(userSettings[tableName].columnOrder);
      }
      if (userSettings?.[tableName]?.columnVisibility) {
        setColumnVisibility(userSettings[tableName].columnVisibility);
      }
      if (userSettings?.[tableName]?.density) {
        setDensity(userSettings[tableName].density);
      }
      if (userSettings?.[tableName]?.sorting) {
        setSorting(userSettings[tableName].sorting);
      }
      if (userSettings?.[tableName]?.pagination) {
        setPagination(userSettings[tableName].pagination);
      }
      // filters
      if (!isFilterQueryParams) {
        if (userSettings?.[tableName]?.isAutosaveFilters) {
          setIsAutosaveFilters(userSettings[tableName].isAutosaveFilters);
        }
        if (userSettings?.[tableName]?.showColumnFilters) {
          setShowColumnFilters(userSettings[tableName].showColumnFilters);
        }
        if (userSettings?.[tableName]?.columnFilters) {
          setColumnFiltersFromJSON(userSettings[tableName].columnFilters);
        }
      }
    };

    useEffect(() => {
      updateTableFromUserSettings();
    }, [isFilterQueryParams]);

    // update the table when query parameters change
    // useEffect(() => {
    //   setColumnFilters([...(isFilterQueryParams ? createFiltersFromQueryParams(qParams) : [])]);
    // }, [qParams]);

    useEffect(() => {
      if (userSettings && tableName && !isUserSettings) {
        updateTableFromUserSettings();
        setTimeout(() => setIsUserSettings(true), 0);
      }
    }, [userSettings]);

    useEffect(() => {
      if (isUserSettings && tableName) {
        saveUserSettings('columnOrder', columnOrder);
      }
    }, [columnOrder]);

    useEffect(() => {
      if (isUserSettings && tableName) {
        saveUserSettings('columnVisibility', columnVisibility);
      }
    }, [columnVisibility]);

    useEffect(() => {
      if (isUserSettings && tableName) {
        saveUserSettings('density', density);
      }
    }, [density]);

    useEffect(() => {
      if (isUserSettings && tableName) {
        saveUserSettings('sorting', sorting);
      }
    }, [sorting]);

    useEffect(() => {
      if (isUserSettings && tableName) {
        saveUserSettings('pagination', { ...pagination, pageIndex: 0 });
      }
    }, [pagination]);

    // filters
    useEffect(() => {
      if (isUserSettings && tableName && !isFilterQueryParams) {
        saveUserSettings('isAutosaveFilters', isAutosaveFilters);
      }
    }, [isAutosaveFilters]);

    useEffect(() => {
      if (isUserSettings && tableName && !isFilterQueryParams) {
        saveUserSettings('showColumnFilters', showColumnFilters);
      }
    }, [showColumnFilters]);

    useEffect(() => {
      if (isUserSettings && tableName && !isFilterQueryParams) {
        saveUserSettings('columnFilters', columnFilters);
      }
    }, [columnFilters, isUserSettings]);
    // END TABLE PERSISTANCE //

    const handleExportRows = () => {
      const sheets = [];
      let wscols = [];
      const allVisibleRows = tableInstanceRef?.current?.getPrePaginationRowModel()?.rows;

      if (allVisibleRows?.length > 0) {
        for (let i = 0; i < allVisibleRows?.length; i++) {
          const currentCells = allVisibleRows[i]?.getVisibleCells();
          if (i === 0) {
            const headerArr = currentCells
              .filter((el: any) => el.column.columnDef?.notExported !== true && el.column?.id !== 'mrt-row-actions')
              .map((el: any) => ({
                v:
                  (el.column.columnDef?.exportHeader && el.column.columnDef?.exportHeader(el)) ||
                  el.column.columnDef.header,
                t: 's',
                s: { font: { bold: true } },
              }));
            sheets.push(headerArr);
            wscols = currentCells
              .filter((el: any) => el.column.columnDef?.notExported !== true && el.column?.id !== 'mrt-row-actions')
              .map((el: any) => ({ wpx: el.column.columnDef.exportColumnWidth || 150 }));
          }
          const currentRow = currentCells
            .filter((el: any) => el.column.columnDef?.notExported !== true && el.column?.id !== 'mrt-row-actions')
            .map((el: any) => {
              return {
                v: el.column.columnDef?.exportValue ? el.column.columnDef?.exportValue(el) : el?.getValue() || '',
                t: (el.column.columnDef?.exportColumnFormat && el.column.columnDef?.exportColumnFormat(el)) || 's',
                z:
                  (el.column.columnDef?.exportColumnFormat && el.column.columnDef?.exportColumnFormat(el)) === 'd'
                    ? 'dd MMM yyy, hh:mm AM/PM'
                    : undefined,
                s: {
                  alignment: {
                    vertical: 'top',
                    ...(el.column.columnDef?.exportWrapText && el.column.columnDef?.exportWrapText(el)
                      ? { wrapText: true }
                      : {}),
                  },
                  ...(el.column.columnDef?.exportColor
                    ? { font: { color: { rgb: el.column.columnDef?.exportColor(el) } } }
                    : {}),
                  ...(el.column.columnDef?.exportBgColor
                    ? { fill: { patternType: 'solid', fgColor: { rgb: el.column.columnDef?.exportBgColor(el) } } }
                    : {}),
                },
              };
            });
          sheets.push(currentRow);
        }
      } else {
        return;
      }

      const wb = utils.book_new();

      const ws = utils.aoa_to_sheet(sheets);

      ws['!cols'] = wscols;

      utils.book_append_sheet(wb, ws);
      writeFile(wb, rest?.excelName ? `${rest.excelName}.xlsx` : 'ExcelExport.xlsx');
    };

    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        {isUserSettings && (
          <MaterialReactTable
            muiTablePaperProps={{
              elevation: 0,
            }}
            muiTopToolbarProps={{
              sx: {
                '&.MuiToolbar-root .MuiBox-root': {
                  alignItems: 'flex-end',
                },
              },
            }}
            muiTableHeadRowProps={{
              sx: {
                // for filters
                '& .MuiSelect-select .MuiBox-root, input': {
                  fontSize: '13px',
                },
                '& .MuiOutlinedInput-root': {
                  pr: 0.5,
                  pl: 0.5,
                },
                '& .MuiInputAdornment-root': { mr: 0, ml: 0 },
                '& .MuiOutlinedInput-input': { pl: 0.5, pr: 0 },
                '& .MuiSelect-select .MuiBox-root': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
                '& .MuiSelect-select.MuiInputBase-input.MuiSelect-select': {
                  paddingRight: '18px !important',
                },
                '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                  fontSize: '16px',
                },
                // for header
                '&.MuiTableRow-head': {
                  '& .Mui-TableHeadCell-ResizeHandle-Wrapper': {
                    opacity: 0,
                    transition: 'none !important',
                    ':active': {
                      '& .MuiDivider-root': {
                        backgroundColor: 'black',
                      },
                    },
                  },
                  '& .Mui-TableHeadCell-Content-Actions': {
                    display: 'none',
                  },
                  ':hover': {
                    '& .Mui-TableHeadCell-ResizeHandle-Wrapper': {
                      opacity: 1,
                    },
                    '& .Mui-TableHeadCell-Content-Actions': {
                      display: 'flex',
                      alignItems: 'center',
                    },
                  },
                },
              },
            }}
            enableColumnOrdering
            enablePinning
            enableColumnDragging
            enableColumnResizing
            filterFns={{
              myCustomFilterFn: (row: any, id, filterValue) => {
                if (row?.getValue(id))
                  return String(row.getValue(id)).toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());
                return false;
              },
            }}
            globalFilterFn="myCustomFilterFn"
            columns={columns}
            data={data}
            localization={getMRTLocale(getLocale())}
            renderTopToolbarCustomActions={() => {
              return !isFilterQueryParams ? (
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                  {children || <span />}
                  <Box>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={isAutosaveFilters}
                          onChange={() => setIsAutosaveFilters((prevValue) => !prevValue)}
                        />
                      }
                      label={<span style={{ fontSize: '13px' }}>{t('table.autosaveFilters')}</span>}
                    />
                    <Button onClick={() => resetAllFilters()} size="small">
                      {t('table.resetFilters')}
                    </Button>
                  </Box>
                </Box>
              ) : (
                children
              );
            }}
            renderToolbarInternalActions={({ table }) => (
              <Box>
                {/* eslint-disable */}
                <MRT_ToggleGlobalFilterButton table={table} />
                <Tooltip title="Export excel" placement="bottom" arrow>
                  <span>
                    <IconButton
                      disabled={tableInstanceRef?.current?.getPrePaginationRowModel().rows.length === 0}
                      onClick={() => handleExportRows()}
                    >
                      <SaveAltIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <MRT_ToggleFiltersButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleDensePaddingButton table={table} />
                <MRT_FullScreenToggleButton table={table} />
                {/* eslint-enable */}
              </Box>
            )}
            icons={fontAwesomeIcons}
            muiTableHeadCellFilterTextFieldProps={{
              sx: { m: '0.3rem 0 0 0' },
              variant: 'outlined',
            }}
            muiSearchTextFieldProps={{
              placeholder: 'Search',
              sx: {
                minWidth: '10rem',
                '& input': {
                  fontSize: '13px',
                },
              },
              variant: 'outlined',
            }}
            muiTableBodyProps={{
              sx: (theme) => ({
                td: { fontSize: 13 },
                ...(styleFirstRow && {
                  'tr.MuiTableRow-root:first-of-type': {
                    'td.MuiTableCell-root': {
                      backgroundColor: 'rgba(21,101,192,0.15)',
                    },
                    ':hover': {
                      'td.MuiTableCell-root': {
                        backgroundColor: 'rgba(21,101,192,0.20)',
                      },
                    },
                  },
                }),
              }),
            }}
            {...rest}
            // START TABLE PERSISTANT //
            onColumnOrderChange={setColumnOrder}
            onColumnVisibilityChange={setColumnVisibility}
            onDensityChange={setDensity}
            onSortingChange={setSorting}
            onPaginationChange={setPagination}
            // filters
            onShowColumnFiltersChange={setShowColumnFilters}
            onColumnFiltersChange={setColumnFilters}
            state={{
              columnOrder,
              columnVisibility,
              density,
              sorting,
              pagination,
              // filters
              showColumnFilters,
              columnFilters,
              isLoading: rest?.state?.isLoading,
            }}
            enableStickyHeader
            // enableStickyFooter
            // muiTableContainerProps={{
            //   sx: {
            //     display: 'flex',
            //     flexDirection: 'column',
            //     flexGrow: 1,
            //     height: '150px',
            //   },
            // }}
            // END TABLE PERSISTANT //

            tableInstanceRef={tableInstanceRef}
          />
        )}
      </Box>
    );
  }
);
